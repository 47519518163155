import { ErrorMessage, Formik, Form, Field } from "formik";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import * as Yup from "yup";
import axios from "axios";
import { url } from "../Api";
import { PageContext } from "../Context/PageContextProvider";
import { toast } from "react-toastify";
import authBg from "../Images/authBg.jpg";
import lightBg from "../Images/lightBg.png";
import logo from "../Images/logo.png";
import authImage from "../Images/authImage.png";

const RestPassword = () => {
  const validate = Yup.string().required("Field is Required!");
  const { setOpenBackDrop } = useContext(PageContext);
  const navigate = useNavigate();

  const onSubmit = (values, onSubmitProps) => {
    setOpenBackDrop(true);
    axios({
      url: `${url}/user/password/new`,
      method: "post",
      data: {
        code: values.code,
        newPassword: values.newPassword,
      },
    })
      .then((result) => {
        setOpenBackDrop(false);
        toast.success(result.data.message);
        navigate("/login");
        onSubmitProps.resetForm();
      })
      .catch((err) => {
        toast.error(err.data.message);
        setOpenBackDrop(false);
      });
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-screen fixed w-full">
      <div
        className="w-full hidden bg-center lg:flex bg-cover bg-no-repeat h-screen"
        style={{ backgroundImage: `url(${authBg})` }}
      ></div>
      {/* <div className="w-full hidden items-center justify-center lg:flex h-screen bg-primary">
        <div className="h-[37rem]">
          <img src={authImage} alt="" className="h-full w-full object-cover" />
        </div>
      </div> */}
      <div
        className="Container w-full h-screen py-10 bg-cover bg-no-repeat text-center relative overflow-x-hidden overflow-y-auto"
        style={{ backgroundImage: `url(${lightBg})` }}
      >
        <div className="formWrap" style={{ maxWidth: 500 }}>
          <div>
            <div
              style={{
                boxShadow:
                  "-2px 2px 5px rgba(0,0,0,0.12), 1px 1px 2px rgba(0,0,0,0.24)",
              }}
              className="pb-5 pt-3 rounded-md px-4 md:px-8 bg-primary"
            >
              <div className="flex justify-center mt-4 mb-6">
                <img src={logo} alt="logo" className="h-[88px]" />
              </div>

              <div className="text-xl my-1 md:text-2xl font-bold inline-block bg-secondary rounded-xl py-[1.8px] px-4">
                Reset Password
              </div>
              <p className="pt-2 pb-7 text-white">
                Fill in the code that was to your email and new password
              </p>

              <Formik
                initialValues={{
                  code: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={Yup.object({
                  code: validate,
                  newPassword: validate.min(
                    8,
                    "Password must not be lass than 8 characters"
                  ),
                  confirmPassword: Yup.string()
                    .oneOf(
                      [Yup.ref("newPassword"), null],
                      "Passwords must match"
                    )
                    .required("Field is required!"),
                })}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="form-group">
                    <label className="text-white">Code</label>
                    <Field
                      type="tel"
                      name="code"
                      placeholder="Enter code"
                      className="bg-gray-50"
                    />
                    <ErrorMessage
                      name="code"
                      component="span"
                      className="errorMsg"
                    />
                  </div>
                  <div className="form-group my-5">
                    <label className="text-white">Password</label>
                    <Field
                      type="password"
                      name="newPassword"
                      placeholder="Enter new password"
                      className="bg-gray-50"
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="span"
                      className="errorMsg"
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-white">Confirm password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter password again"
                      className="bg-gray-50"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="span"
                      className="errorMsg"
                    />
                  </div>
                  <button
                    className="button w-full mt-7"
                    style={{ background: "var(--secondary)" }}
                  >
                    Submit
                  </button>
                </Form>
              </Formik>
              <div className="flex justify-end pt-1">
                <div>
                  <Link
                    to="/forget-password"
                    className="cursor-pointer text-secondary hover:text-white text-sm mt-2"
                  >
                    Back to forgot password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestPassword;
